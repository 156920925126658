import React, {useEffect, useState} from 'react';
import TuneDataService from '../services/tune.service.js';
import ComposerDataService from '../services/composer.service.js';
import TextareaAutosize from 'react-textarea-autosize'
import Fuse from 'fuse.js';
type tune = {
  "title"?: string
  "bio"?: string
  "alternative_title"?: string
  "composer_placeholder"?: string
  "Composers"?: composer[]
  "form"?: string
  "notable_recordings"?: string[]
  "keys"?: string[]
  "styles"?: string[]
  "tempi"?: string[]
  "contrafacts"?: string[] // In the future, these could link to other tunes
  "id"?: number
  "year"?: number
  "played_at"?: string[]
}
type composer = {
  "name": string
  "id": number
  "birth": string
  "death": string
  "bio": string
}
const tuneEditorFields = new Array<[string, string]>(
  ["title", "Title:"],
  ["alternative_title", "Alternative Title:"],
  ["composer_placeholder", "Composers (placeholder)"],
  ["bio", "Bio:"],
  ["form", "Form:"], 
  ["year", "Year:"]
);
const composerEditorFields = new Array<[string, string]>(
  ["name", "Name:"],
  ["birth", "Birth:"],
  ["death", "Death:"],
  ["bio", "Bio:"],
);

//Defaults for both composers and tunes.
const defaults = {
  "title": "New song",
  "name" : "New composer",
  "alternative_title": "",
  "bio": "",
  "composers": [],
  "form": "",
  "birth": "",
  "death": "",
  "notable_recordings": [],
  "keys": [],
  "styles": [],
  "tempi": [],
  "contrafacts": [], 
  "played_at": [], 
  "composer_placeholder": "",
  "id": -1 // If the user sees this text at any point, there's an error in the program
}

function EditField({attr, data, composerDataService}:
{attr: string, data: tune | composer, composerDataService: ComposerDataService}){
  let value = data[attr] ? data[attr] : defaults[attr];
  //Notice the pattern below, it can be abstracted.
  //<textarea classname=..."
  //  defaultValue?
  //  name={attr}
  //  autoComplete="off"
  //  type?
  //  min?
  //  max?
  switch(attr){
    case "bio":

      return(<TextareaAutosize className="bg-black text-base"
        defaultValue={data[attr] ? data[attr] : ""}
        placeholder='(Empty)'
        name={attr}
        autoComplete='off'
        style={{resize: "none"}}
        />);
      case "birth":
      case "death":
        return(<input className="bg-black text-base"
               type="date"
               name={attr}
              />);
      case "year":
        return(<input className="bg-black text-base"
               type="number"
               name={attr}
               defaultValue={0}
               min={0}
               max={3000}
              />);

  }
  switch(typeof value){
    case "string":
      return(<input className="bg-black text-base"
        defaultValue={data[attr] ? data[attr] : ""}
        placeholder='(Empty)'
        name={attr}
        autoComplete='off'
        />);
  }
}

export default function TuneEdit({data, setEditing, tuneDataService, composerDataService, isComposer, composers=[]}
  : {data: tune | composer, setEditing: Function, tuneDataService: TuneDataService, composerDataService:ComposerDataService, isComposer: boolean, composers: composer[], selectedComposers: Array<number>}){
  const updating = typeof data.id !== "undefined";
  const [newComposers, setNewComposers] = useState([] as composer[]);
  const [error, setError] = useState("");
  useEffect(() => {
    setNewComposers("Composers" in data ? data.Composers as Array<composer> : []);
  }, [data, data.id])
  function handleSubmit(e: React.FormEvent) {
    //Prevent browser from reloading page
    e.preventDefault();

    //Read form data
    const form = e.target;
    const formData = new FormData(form as HTMLFormElement);

    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);
    let promise: Promise<any> = Promise.resolve();

    const service = isComposer ? composerDataService : tuneDataService;

    if(updating){
      promise = service.update(data.id, JSON.stringify(formJson)).catch((error) => {
        setError(error.response.data);
      });

    }else{
      promise = service.create(JSON.stringify(formJson)).catch((error) => {
        setError(error.response.data);
      });
    };

    promise.then((result) => {
      if(result){
        composerDataService.addToTune(result.data.id, JSON.stringify({composerList: newComposers.map(comp => comp.id)}))
        setEditing(false);
      }
    });
  }
  const listItems = (isComposer ? composerEditorFields : tuneEditorFields).map( ([attr, pretty]) =>
      <div className='m-5 bg-slate-800' key={attr}>
        <label className='bg-slate-800'>
          <p className='text-2xl'>
            {pretty}
          </p>

          {(attr === "title" || attr === "name") && ((data as tune).title || (data as composer).name) &&
          <p className="text-sm">(Previously {isComposer ? (data as composer).name : (data as tune).title})</p>}

          <EditField attr={attr} data={data} composerDataService={composerDataService}/>
        </label>
      </div>
  );
  return(
    <div className='h-screen flex-1 overflow-auto'>
      { error !== "" &&
        <div className='bg-red-950'>
          <p>An error occured while submitting the form!</p>
          <p>{JSON.stringify(error)}</p>
        </div>
      }
      {!isComposer && <CSelectList list={composers} newComposers={newComposers} setNewComposers={setNewComposers}/>}
      <form id="edit-form" className='m-1' onSubmit={handleSubmit}>
        {listItems}
        <button type='submit' className='m-3 p-2 bg-slate-700 text-lg'>
          Submit {updating ? "edit" : "creation"}
        </button>
        <button type='reset' className='m-3 p-2 bg-slate-700 text-lg'>
          Reset {updating ? "edit" : "creation"}
        </button>
        <p className='text-sm'>Entries are reviewed before adding to the site</p>
      </form>
    </div>
  );
}
function CSelectList({list, newComposers, setNewComposers}:
                     {list: composer[], newComposers: composer[], setNewComposers: Function}){
  const fuse = new Fuse(list, {keys: ["name"]})
  const [results, setResults] = useState(list);
  const [search, setSearch] = useState("");
  const handleSearch = (search: string) => {
    setResults(fuse.search(search).map((result) => result.item)); 
    setSearch(search)
  };
  const addNewComposer = ((comp: composer) => {
    setNewComposers(newComposers.concat(comp));
  });
  const removeNewComposer = ((comp: composer) => {
    setNewComposers(newComposers.filter(cposer => cposer.id !== comp.id));
  });

  const allComposers = (search === "" ? list : results).map((result) => 
    !newComposers.find(comp => comp.id === result.id) &&
      <div className='' key={result.id}>
        <button className='bg-slate-800 justify-items-start' onClick={() => {
          addNewComposer(result);
        }}>
          {
            <p className='text-base'>
              {result.name}
            </p>
          }
        </button>
      </div>
  );
  const selectedComposers = list.map((item: composer) => 
    newComposers.find(comp => comp.id === item.id) &&
      <div className='' key={item.id}>
        <button className='bg-slate-700 justify-items-start' onClick={() => {
          removeNewComposer(item);
        }}>
          {
            <p className='text-base'>
              {item.name}
            </p>
          }
        </button>
      </div>
  );
  return(
    <div className="m-5 bg-slate-800 overflow-y-scroll max-h-72">
      <p className='text-sm'>
        Selected composers appear at the top
      </p>
      <p className='text-sm'>
        The "reset" button doesn't affect this field.
      </p>
        <input type="text" id="search" className="bg-black text-base" placeholder="Search composer" onChange={(e) => handleSearch(e.target.value)}/>
        <p className='text-2xl'>
        Select composers to add:
      </p>
      <div>
        {selectedComposers}
        {allComposers}
      </div>
    </div>
  );
}
